import { ThemeProvider } from '@material-ui/core/styles';
import { Route, HashRouter, Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '../utils/PrivateRoute';

import Theme from '../theme/theme';
import Login from './users/login/Login';
import Index from './dashboard/Index';

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <HashRouter>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <PrivateRoute path="/dashboard">
            <Index />
          </PrivateRoute>
          <Route exact path="/">
            <Redirect exact from="/" to="dashboard" />
          </Route>
          <Route path="*">
            <Redirect from="/" to="dashboard" />
          </Route>
        </Switch>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
