import React, { useEffect, useState, forwardRef, useCallback }from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import Moment from 'moment';
import { useHotkeys } from 'react-hotkeys-hook';
import fileDownload from 'js-file-download';
import { 
  Box,
  Button,
  Divider,
  LinearProgress,
  Tabs,
  Tab,
  Typography,
} from '@material-ui/core';
import {
  CalendarToday,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import TabService from './TabService';
import TabAccount from './TabAccount';

import BillingReportBtn from './components/BillingReportBtn';
import SnackbarMsg from '../../../../utils/SnackbarMsg';

const useStyles = makeStyles({
  root: {
    '& .css-19kzrtu': {
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0
    },
    '& .MuiAccordionDetails-root': {
      paddingRight: 0
    },
  },
  select: {
    fontSize: '18px',
    padding: '10px 24px',
    marginLeft: '8px',
    '& .MuiButton-endIcon': {
      marginLeft: '16px'
    }
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Billing(props) {
  const { token } = props;
  const { 
    REACT_APP_LOCAL_BILLING, 
    REACT_APP_LOCAL_BILLING_REPORT, 
    REACT_APP_RELEASE_BILLING, 
    REACT_APP_RELEASE_BILLING_REPORT 
  } = process.env;
  const history = useHistory();
  const classes = useStyles();
  const [tab, setTab] = React.useState(0);
  const [subTotal, setSubTotal] = React.useState(null);
  const [didMount, setDidMount] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [tabServiceData, setTabServiceData] = React.useState(null);
  const [tabAccountData, setTabAccountData] = React.useState(null);
  const [startDate, setStartDate] = React.useState(new Date());
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button variant="outlined" className={classes.select} onClick={onClick} ref={ref} endIcon={<CalendarToday />}>
      {value}
    </Button>
  ));

  const handleMonthChange = (date) => {
    setStartDate(startDate => startDate = date);
    setLoaded(true);
    handleDataTabResponse(date);
  }

  const callServiceData = async(date) => {
    await axios.get(`${REACT_APP_RELEASE_BILLING}`,{
      params: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        by_region: 1
      },
      headers: { 
        "Authorization": `JWT ${token}`,
        "Accept": "application/json",
        "Cache-Control": "max-age=900"
      },
    }).then(res => {
      setTabServiceData(res.data.productFamily);
      setSubTotal(res.data.subTotal);  
    }).catch(err => {
      responseError()
    })
  }

  const callAccountData = async(date) => {
    await axios.get(`${REACT_APP_RELEASE_BILLING}`,{
      params: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        by_region: 0
      },
      headers: { 
        "Authorization": `JWT ${token}`,
        "Accept": "application/json",
        "Cache-Control": "max-age=900"
      },
    }).then(res => {
      setTabAccountData(res.data.account); 
    }).catch(err => {
      responseError()
    })
  }

  const responseError = () => {
    setErrorMsg(true);
    setLoaded(true);
    setTimeout(() => {
      history.push('./login');
      localStorage.removeItem('user');
    }, 3000);
  }
  
  const handleDownloadCSV = async() => {
    try {
      const response = await axios({
        method: "get",
        url: `${REACT_APP_RELEASE_BILLING_REPORT}`,
        params: {
          year: startDate.getFullYear(),
          month: startDate.getMonth() + 1,
        },
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      });
      fileDownload(response.data, `${Moment(startDate).format('YYYYMM')}.csv`)
    } catch {
      console.log('error')
    }
  }

  useHotkeys('shift+p', handleDownloadCSV, {},[startDate])

  const handleDataTabResponse = (date) => {
    axios.all([callServiceData(date), callAccountData(date)])
    .then(axios.spread((serviceResopse, accountResponse) => {  
      setLoaded(false);
    }))
    .catch(error => {
      responseError()
    });
  }

  useHotkeys('shift+p', () => handleDownloadCSV())
  
  useEffect(() => {
    setDidMount(true)
  }, [])

  useEffect(() => {
    setLoaded(true);
    handleDataTabResponse(startDate);
  }, [])

  return (
    <>
      {
        didMount &&
        <Box width="1152px" margin="0 auto" pl={2} pr={2} backgroundColor="#FFFFFF"  borderRadius="4px" boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.54)">
          <Box>
            <Typography variant="h1" display="block" fontSize={20} color="#1976d2" fontWeight="bold" padding="10px 0">帳單</Typography>
            <Divider />
          </Box>
          <Box pt={2} pb={2} display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography variant="div" display="block" fontSize={15} color="#212121" whiteSpace="nowrap">日期：</Typography>
              <DatePicker 
                selected={startDate}
                onChange={(date) => handleMonthChange(date)}
                customInput={<ExampleCustomInput />}
                dateFormat="yyyy-MM"
                showMonthYearPicker
                showFullMonthYearPicker 
              />
            </Box>
            <Box>
              <BillingReportBtn handleDownloadCSV={handleDownloadCSV} />
            </Box>
          </Box>
          <Box pb={2} fontSize={14}>
            <Typography variant="p" display="block" color="#212121">在此頁面上顯示的預估費用、或我們傳送給您的任何通知中顯示的估計費用，可能與帳單明細期間的實際費用有所出入。</Typography>
            <Typography variant="p" display="block" color="#212121">這是因為此頁面上呈現的估計費用，不包含在您查看此頁面的日期之後的帳單明細期間所累積的使用費。</Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Tabs value={tab} onChange={handleChange} aria-label="tabs">
                <Tab label="依服務區分的帳單詳細資訊" {...a11yProps(0)} />
                <Tab label="依帳戶區分的帳單詳細資訊" {...a11yProps(1)} />
              </Tabs>
              {/* <Button variant="outlined" endIcon={<AddCircleOutline />}>全部展開</Button> */}
            </Box>
            {loaded && <Box pt={2.5} pb={2.5}><LinearProgress /></Box>}
            {!loaded && 
              <Box pt={3} pb={5} height={500} overflow="auto">
                <TabPanel value={tab} index={0} className={classes.root}>
                  {tabServiceData && <TabService subTotal={subTotal} tabServiceData={tabServiceData} />}    
                </TabPanel>
                <TabPanel value={tab} index={1}  className={classes.root}>
                  {tabAccountData && <TabAccount tabAccountData={tabAccountData} />}
                </TabPanel>
              </Box>
            }           
          </Box>
          {errorMsg && <SnackbarMsg setErrorMsg={setErrorMsg} errorMsg={errorMsg} />}
        </Box>
      }
    </>
  )
}

export default Billing