import React, { useEffect, useState } from 'react';
import { injectIntl, FormattedNumber } from 'react-intl';

import { 
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from '@material-ui/core';
import {
  ArrowDropDown,
} from '@material-ui/icons';
import ChildMenu from './components/AccordionSubMenu';

const TabService = (props) => {
  const { intl, subTotal, tabServiceData } = props;

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" pt={2.5} pb={2.5} pr={3} borderBottom="1px solid#e0e0e0">
        <Typography variant="div" fontWeight="bold">AWS 服務費用</Typography>
        <Typography variant="div" fontWeight="bold">{`$ ${intl.formatNumber(subTotal)}`}</Typography>
      </Box>
      <Box>
        {tabServiceData.map((element, index) => {
          return(
            <Accordion style={{ boxShadow: 'none' }} key={index}>
              <AccordionSummary
                expandIcon={<ArrowDropDown />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ padding: 0, flexDirection: "row-reverse", minHeight: "54px" }}
              >
                <Box display="flex" justifyContent="space-between" width="100%" pr={3}>
                  <Typography variant="div">{element.name}</Typography>
                  <Typography variant="div">{`$ ${intl.formatNumber(element.subTotal)}`}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <ChildMenu element={element}/>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </Box>
    </Box>
  )
}

export default injectIntl(TabService);
