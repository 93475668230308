import React from 'react';

import { 
  Button,
} from '@material-ui/core';
import {
  GetApp
} from '@material-ui/icons';

function BillingReportBtn(props) {
  const { handleDownloadCSV } = props;

  return (
    <Button onClick={handleDownloadCSV} variant="outlined" endIcon={<GetApp />}>下載 CSV</Button>
  )
}

export default BillingReportBtn;
