import React from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { 
  Button, 
  Box,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import styled from 'styled-components';
import LoginBg from '../../../assets/images/bg.png';
import LoginImg from '../../../assets/images/logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-input": {
      background: "#FFFFFF"
    }
  }
}));

const Container = styled(Box)`
  display: flex;
  align-items: center; 
  justify-content: center; 
  width: 100%;
  height: 100vh;  
  background-repeat: no-repeat;
  background-image: url(${LoginBg});
`

const CompanyLogo = styled.img`
  width: 40px;
  border-radius: 50%;
  margin-bottom: 12px;
`


export default function Login() {
  const { REACT_APP_LOCAL_USER, REACT_APP_RELEASE_USER } = process.env;
  const classes = useStyles();
  const history = useHistory();
  const [formData, setFormData] = React.useState({
    username: '',
    password: ''
  })
  const [errors, setErrors] = React.useState({
    username: false,
    password: false
  })

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData, 
      [name]: value,      
    }); 
    setErrors({
      ...errors,
      [name]: false
    })
  }

  const onSubmit = async() => {
    const loginFormData = new FormData();
    loginFormData.append("username", formData.username)
    loginFormData.append("password", formData.password)
    const values = [
      {
        name: 'username',
        value: formData.username
      },
      {
        name: 'password',
        value: formData.password
      }
    ]

    try {
      const response = await axios({
        method: "post",
        url: `${REACT_APP_RELEASE_USER}`,
        data: loginFormData,
        headers: { 
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      localStorage.setItem('user', JSON.stringify(response));
      history.push('/')
    } catch(error) {
      if(error.response.status) {
        values.forEach(item => {
          setErrors({
            ...errors,
            username: true,
            password: true
          })
        })
      } else {
        values.forEach(item => {
          setErrors({
            ...errors,
            username: false,
            password: false
          })
        })
      }
    }
  }

  return (
    <Container>
      <Box style={{ width: 396, margin: '0 auto', borderRadius: '12px', boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.16)', border: 'solid 1px #707070', padding: '42px 94px', background: '#FFFFFF' }}>
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Box display="flex" alignItems="center" flexDirection="column" mb={8}>
            <CompanyLogo src={LoginImg} alt="logo" />
            <Typography variant="h2" display="block" fontWeight="bold" fontSize={28} ml={1.5} letterSpacing={1}>Ubitus Billing Portal</Typography>
          </Box>
          <Typography variant="h5" display="block" fontWeight="normal" fontSize={24}>Login</Typography>
        </Box>
        <TextField 
          id="userName" 
          label="Username" 
          variant="outlined" 
          fullWidth 
          style={{ marginTop: 24 }} 
          name="username" 
          value={formData.username}
          onChange={handleChange}
          error={errors.username}
          helperText={errors.username && 'The username is incorrect.'}
          className={classes.root}
        />
        <TextField 
          id="password" 
          label="Password" 
          variant="outlined"
          type={formData.showPassword ? 'text' : 'password'} 
          fullWidth 
          style={{ marginTop: 24 }} 
          name="password" 
          value={formData.password}
          onChange={handleChange}
          error={errors.password}
          helperText={errors.password && 'The password is incorrect.'}
          className={classes.root}
        />
        <Box pt={4}>
          <Button variant="contained" type="submit" fullWidth onClick={onSubmit}>
            <CircularProgress size={14} />
            Login
          </Button>
        </Box>
        <Typography variant="body2" display="block" fontSize={13} color="#666666" textAlign="center" mt={2.25}>
          Copyright © Ubitus Taiwan All rights reserved.
        </Typography>
      </Box>
    </Container>
  )
}
