import React from 'react';
import { 
  Alert,
  Snackbar
} from '@material-ui/core';

function SnackbarMsg(props) {
  const { errorMsg, setErrorMsg } = props;
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMsg(false);
  };

  return (
    <Snackbar open={errorMsg} autoHideDuration={5000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>連線逾時，將導回至登入頁面</Alert>
    </Snackbar>
  )
}

export default SnackbarMsg
