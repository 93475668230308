import React from 'react';
import { Link } from 'react-router-dom';
import { 
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  Description,
} from '@material-ui/icons';

function AdminMenu(props) {
  const navItems = [
    {
      index: 1,
      name: '帳單',
      path: '/',
      icon: <Description />
    },
  ]
  return (
    <List>
      {navItems.map((element, index) => (
        <ListItem button key={element.index} component={Link} to={`/dashboard${element.path}`}>
          <ListItemIcon>{element.icon}</ListItemIcon>
          <ListItemText primary={element.name} />
        </ListItem>
      ))}
    </List>
  )
}

export default AdminMenu;
