import React from 'react';
import { injectIntl, FormattedNumber } from 'react-intl';
import { 
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from '@material-ui/core';
import {
  ArrowDropDown,
} from '@material-ui/icons';

function AccordionSubMenu(props) {
  const { intl, element } = props;

  const handleProductName = (productName) => {
    return(
      productName.map((product, index) => {
        return(
          <Box key={index}>
            {
              product.componentType.map((des, index) => {
                return(
                  <Box key={index}>
                    <Box backgroundColor="#f6f6f6" color="#626262" pt={2} pb={2} pl={3} pr={3} mt={2} display="flex" justifyContent="space-between" borderBottom="1px solid #e0e0e0">
                      <Typography variant="div" fontSize={15} minWidth={414}>{`${product.name} ${des.name}`}</Typography>
                      <Typography variant="div">{`$ ${intl.formatNumber(des.subTotal)}`}</Typography>
                    </Box>
                    {
                      des.description.map((detail, index) => {
                        return(
                          <Box key={index} backgroundColor="#f6f6f6" color="#626262" pt={2} pb={2} pl={3} pr={3} display="flex" justifyContent="space-between" borderBottom="1px solid #f6f6f6">
                            <Typography variant="div" fontSize={15} minWidth={413} maxWidth={414}>{detail.name}</Typography>
                            <Typography variant="div" fontSize={15}>{`${intl.formatNumber(detail.usage)} ${detail.usageUnit} `}</Typography>
                            <Typography variant="div">{`$ ${intl.formatNumber(detail.cost)}`}</Typography>
                          </Box>
                        )
                      })
                    }
                  </Box>
                )
              })
            }
          </Box>
        )
      })
    )
  }
  
  return (
    <Box>
      {
        element.region.map((pos, index) => {
          return(
            <Accordion style={{ boxShadow: 'none' }} key={index}>
              <AccordionSummary
                expandIcon={<ArrowDropDown />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ padding: 0, flexDirection: "row-reverse", minHeight: "54px" }}
              >
                <Box display="flex" justifyContent="space-between" width="100%" pr={3}>
                  <Typography variant="div" fontWeight="bold">{pos.name}</Typography>
                  <Typography variant="div" fontWeight="bold">{`$ ${intl.formatNumber(pos.subTotal)}`}</Typography>
                </Box>                   
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                {handleProductName(pos.productName)}  
              </AccordionDetails>
            </Accordion>
          )
        })
      }
    </Box>
  )
}

export default injectIntl(AccordionSubMenu);
