import React, {  useEffect } from 'react';
import { useHistory, Route, Switch } from 'react-router-dom';

import { styled, useTheme } from '@material-ui/core/styles';
import { 
  Avatar,
  AppBar,
  Button,
  Box,
  CssBaseline,
  Drawer,
  Toolbar,
  MenuItem,
  Tooltip,
  Typography,
  IconButton,
  Menu
} from '@material-ui/core';
import {
  ChevronLeft,
  ChevronRight,
  ExitToApp,
} from '@material-ui/icons';
import AppMenu from '@material-ui/icons/Menu';
import DashboardBg from '../../assets/images/bg.png';

import AdminMenu from '../../components/Menu/Menu';
import Billing from '../dashboard/admin/billing/Billing';
import NotFound from '../error/404';

export default function Index() {
  const { REACT_APP_URL } = process.env;
  const [open, setOpen] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
  const [user, setUser] = React.useState({});
  const [userData, setUserData] = React.useState({});
  const [token, setToken] = React.useState('');
  const theme = useTheme();
  const history = useHistory();
  const drawerWidth = 240;

  const Container = styled(Box)`
    display: flex;
    justify-content: center; 
    width: 100%;
    height: 100%;  
  `

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(9)} + 1px)`,
    },
  });

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  const NavBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const MuiDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    history.push('/login');
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const getUser = JSON.parse(localStorage.getItem('user'))
    if(getUser){
      setToken(getUser.data.access)
    }else{
      history.push('./login')
    }
  }, [])

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <div className="App">
      {
        mounted &&
          <Container>
          <CssBaseline />
          <NavBar position="fixed" open={open} style={{ backgroundColor: "#266174" }} >
            <Toolbar>
              {/* <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: '36px',
                  ...(open && { display: 'none' }),
                }}
              >
                <AppMenu />
              </IconButton> */}
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="h6" noWrap component="div">
                  Ubitus <Typography variant="span" component="span" fontSize={16} fontWeight="normal" ml={2}>Billing Portal</Typography>
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <Button variant="container" startIcon={<ExitToApp />} onClick={handleLogout} style={{ border: '1px solid #ffffff' }}>Logout</Button>
              </Box>
            </Toolbar>
          </NavBar>
          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
          >
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
              <AdminMenu/>
            </Box>
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            <Box width="100%" height="100%"> 
              <Switch>
                <Route exact path={`/dashboard`}>
                  <Billing token={token} />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </Box>
          </Box>
        </Container>
      }
    </div>
  )
}
