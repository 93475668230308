import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Noto Sans TC',
      'sans-serif',
      'Roboto',
    ].join(','),
    body1:{
      fontWeight: 400
    }
  },
})

export default theme;