import React from 'react'
import { Box, Typography } from '@material-ui/core';

import styled from 'styled-components';

import NotFound from '../../assets/images/404.png';

const NotFoundImg = styled.img`
  width: 32.69rem;
`

function NotFound404() {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
      <Box width="100%" backgroundColor="#ffffff" textAlign="center">
        <Box style={{ transform: "translateY(-20px)" }}>
          <NotFoundImg src={NotFound} alt="404"/>
          <Box maxWidth="270px" m="0 auto">
            <Typography variant="h2" component="div" color="#626262" fontSize="42px" fontWeight="400">Whoops!</Typography>
            <Typography variant="p" component="div" color="#949494" fontSize="18px" margin="12px 0 36px">
              We couldn't find the page you were looking for.
            </Typography>
            <Typography variant="p" component="div" color="#949494" fontSize="13px" mb={2.5}>Copyright © Ubitus K.K. All rights reserved.</Typography>
          </Box>          
        </Box>      
      </Box>
    </Box>
  )
}

export default NotFound404;
